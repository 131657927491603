<template>
  <custom-modal :opened="isOpened"
                :max-width="520"
                @closeModal="closeModal"
                class-name="create-summary-modal"
  >
    <template v-slot:name>
      <div class="title">Reviews AI Summary Creating</div>
    </template>

    <template v-slot:footer-action>
      <div class="footer">
        <base-button height="36px"
                     width="210px"
                     :disabled="!isCreationEnabled"
                     border-radius="4px"
                     @click="submit">
          <span>Create Summary</span>
        </base-button>

        <base-button height="36px"
                     width="150px"
                     border-radius="4px"
                     @click="closeModal"
                     mode="outline">
          <span>Cancel</span>
        </base-button>
      </div>
    </template>

    <div class="content-block">
      <div>
        <div class="label">Select App</div>
        <div>
          <live-search-with-presets :selected-app="selectedApp"
                                    :show-select-from-competitors="showSelectFromCompetitors"
                                    :current-country="selectedCountry"
                                    :presets="myApps"
                                    :countries="countries"
                                    :is-countries-loading="isCountriesLoading"
                                    store="both"
                                    placeholder="Type to find any app from stores..."
                                    :show-current="true"
                                    @changed="changeApp"
          />
        </div>
      </div>

      <div class="country-locale">
        <div v-if="showLocales">
          <div class="label">Select Locale</div>
          <div class="select-locale-wrap">
            <language-selector :current-language="selectedLocale"
                               :current-country-name="currentCountry.name"
                               :languages="countriesLocalesObject"
                               placeholder="Choose locale"
                               loader-text="Loading locales..."
                               :is-languages-loaded="!isLocalesLoading"
                               @language-changed="selectedLocale = $event"
            />
            <table-actions-tooltip class="locale-tooltip">
              <template v-slot:tooltip-icon>
                <svg-icon icon="question-circle-solid"/>
              </template>
              <template v-slot:tooltip-text>
                This choice determines which reviews will be parsed and analyzed.
                Note that this selection does not change the language of the generated analysis;
                you will receive the analysis in English regardless of the locale selected.
              </template>
            </table-actions-tooltip>
          </div>
        </div>
        <div v-else>
          <div class="label">Select or Change Country for Reviews Analysis</div>
          <select-country :current-country="selectedCountry"
                          :countries="countries"
                          key="country-selector"
                          class="country-selector"
                          :is-loading="isCountriesLoading"
                          @changed="selectedCountry = $event"
          />
        </div>
      </div>

      <div>
        <div class="label">Select Time Frame</div>

        <datepicker-with-range-old
          v-if="dateFilter"
          :to-right="true"
          :date-filter="dateFilter"
          :min-date="minDate"
          :max-date="maxDate"
          :columns="2"
          :ranges="[30, 90, 180, 365]"
          :key="datePickerRerenderKey"
          @date-filter-changed="applyDatePicker"/>
      </div>

      <div>
        <div class="label">Select Review Ratings</div>
        <div>
          <radio-button :active="ratingFrom === 1 && ratingTo === 5"
                        @click="ratingFrom = 1; ratingTo = 5">
            All Ratings
          </radio-button>
          <radio-button :active="ratingFrom === 4 && ratingTo === 5"
                        @click="ratingFrom = 4; ratingTo = 5">
            Only Positive (4-5 Stars <div class="css-icons star-icon"></div>)
          </radio-button>
          <radio-button :active="ratingFrom === 1 && ratingTo === 2"
                        @click="ratingFrom = 1; ratingTo = 2">
            Only Negative (1-2 Stars <div class="css-icons star-icon"></div>)
          </radio-button>
        </div>
      </div>
    </div>
  </custom-modal>

</template>

<script>
import SelectCountry from "@/components/Dropdowns/SelectCountry/index.vue";
import {mapGetters} from "vuex";
import {httpRequest} from "@/api";
import LiveSearchWithPresets from "@/components/Dropdowns/LiveSearchWithPresets/index.vue";
import myApps from "@/api/modules/my-apps";
import DatepickerWithRangeOld from "@/components/UI/DatepickerWithRangeOld/index.vue";
import {formatDate} from "@/utils/utils";
import RadioButton from "@/components/UI/RadioButton/index.vue";
import LanguageSelector from "@/views/SearchTerms/KeywordsAutosuggest/components/LanguageSelector/index.vue";
import TableActionsTooltip from "@/components/DataTables/Tools/TableActionsTooltip/index.vue";
import SvgIcon from "@/components/UI/SvgIcon/index.vue";

export default {
  name: 'CreateSummaryModal',
  components: {
    SvgIcon, TableActionsTooltip,
    LanguageSelector,
    RadioButton,
    DatepickerWithRangeOld,
    LiveSearchWithPresets,
    SelectCountry,
  },
  emits: [
    'created',
    'limit_reached',
    'error',
  ],
  props: {
    presetData: Object,
  },
  data() {
    return {
      isOpened: false,
      selectedCountry: {},
      selectedApp: {},
      countries: [],
      isCountriesLoading: true,
      myApps: [],
      dateFilter: null,
      minDate: new Date(),
      maxDate: new Date(),
      datePickerRerenderKey: 0,
      ratingFrom: 1,
      ratingTo: 5,
      inProcess: false,
      countriesLocalesObject: [],
      selectedLocale: 'en',
      isLocalesLoading: true,
    };
  },
  mounted() {
    const today = new Date();
    let date = new Date(today.getFullYear(), today.getMonth() - 11, today.getDay());
    date.setHours(0, 0, 0, 0);
    this.minDate = date;

    this.setDefaultData();

    this.fetchMyApps();
    this.fetchCountries();
  },
  methods: {
    changeApp(app, country) {
      this.selectedApp = app;
      this.selectedCountry = country;
    },
    async fetchLocales() {
      if (this.showLocales && this.selectedApp?.store?.key !== undefined && this.selectedCountry.code !== undefined) {
        this.isLocalesLoading = true;
        let localesData = await httpRequest(
          'GET',
          process.env.VUE_APP_API_URL + this.$ApiUrls.user.COUNTRY_LOCALES
            + `/${this.selectedApp?.store?.key}/${this.selectedCountry.code}/no`
        );
        this.countriesLocalesObject = {
          default: localesData?.default,
          popular: this.prepareLocales(localesData?.popular),
          other: this.prepareLocales(localesData?.other),
        };
        this.selectedLocale = localesData?.default;
        this.isLocalesLoading = false;
      } else {
        this.isLocalesLoading = false;
      }
    },
    prepareLocales(obj) {
      let localesArr = [];
      for (const key in obj) {
        localesArr.push({
          code: key,
          name: obj[key],
        })
      }
      return localesArr;
    },
    setDefaultData() {
      this.dateFilter = {...this.getLastDaysRange(30)};

      this.selectedCountry = {
        code: this.currentCountry?.code,
        name: this.currentCountry?.name,
        id: this.currentCountry?.id,
      };
      this.ratingFrom = 1;
      this.ratingTo = 5;
      this.selectedApp = {
        id: this.currentApp?.id,
        info: {
          logo: this.currentApp?.logo,
          title: this.currentApp?.title,
          store_link: this.currentApp?.store_link,
        },
        store: this.currentApp?.store,
        title: this.currentApp?.title,
        logo: this.currentApp?.logo,
      }
    },
    getLastDaysRange(days) {
      const nowStart = new Date();
      const nowEnd = new Date();
      return {
        start: nowStart.setDate(nowStart.getDate() - days),
        end: nowEnd.setDate(nowEnd.getDate()),
      }
    },
    applyDatePicker(event) {
      let tempFilterObj = {
        start: event?.start,
        end: event?.end,
      }
      this.dateFilter = {...tempFilterObj};
    },
    async fetchCountries() {
      let result = await httpRequest(
        'GET',
        process.env.VUE_APP_API_URL + this.$ApiUrls.keywordsTracking.SEARCH_KEYWORDS_COUNTRIES
        + '/' + this.currentApp.id + '/countries'
      );
      let countries = [];
      result.countries.forEach((country) => {
        countries.push({
          name: country.name,
          code: country.code,
          id: country.id,
        })
      });
      this.countries = countries;
      this.isCountriesLoading = false;
    },
    async fetchMyApps() {
      this.myApps = await myApps.getAllMyApps();
    },
    openModal() {
      this.isOpened = true;
    },
    closeModal() {
      this.setDefaultData();
      this.isOpened = false;
    },
    async submit() {
      this.inProcess = true;
      let url = process.env.VUE_APP_API_URL + this.$ApiUrls.reviewSummaries.SUMMARY_CREATE
        + '?application_id=' + this.selectedApp.id
        + '&country_code=' + this.selectedCountry.code
        + '&locale_code=' + this.selectedLocale
        + '&date_from=' + formatDate(this.dateFilter.start, 'digits-dash')
        + '&date_to=' + formatDate(this.dateFilter.end, 'digits-dash')
        + '&rating_from=' + this.ratingFrom
        + '&rating_to=' + this.ratingTo;
      let result = await httpRequest('POST', url);

      if (result.is_limit_reached) {
        this.$emit('limit_reached');
        this.closeModal();
      } else if (result.result === undefined) {
        this.$emit('error');
        this.inProcess = false;
      } else {
        this.setDefaultData();
        this.$emit('created');
        this.inProcess = false;
        this.closeModal();
      }
    },
  },
  computed: {
    ...mapGetters([
      'currentCountry',
      'currentApp',
    ]),
    isCreationEnabled() {
      return this.selectedCountry?.code && this.selectedApp?.id && !this.inProcess;
    },
    showLocales() {
      return this.selectedApp.store.key === 'GOOGLE_PLAY';
    },
    showSelectFromCompetitors() {
      return this.myApps.find(app => app.id === this.selectedApp.id) !== undefined;
    },
  },
  watch: {
    selectedApp(oldVal, newVal) {
      if (oldVal?.id !== newVal?.id) {
        this.fetchLocales();
      }
    },
    selectedCountry(oldVal, newVal) {
      if (oldVal?.code !== newVal?.code) {
        this.fetchLocales();
      }
    },
    async presetData() {
      if (this.presetData.app !== undefined) {
        let app = {...this.presetData.app};
        app.info = {
          logo: app.logo,
          store: app.store,
          store_link: app.store_link,
        };
        app.store = {
          key: app.store,
        };
        this.selectedApp = app;
        this.selectedCountry = this.presetData.country;
        this.dateFilter = {
          start: this.presetData.dateFrom,
          end: this.presetData.dateTo,
        };
        this.ratingFrom = this.presetData.ratingFrom;
        this.ratingTo = this.presetData.ratingTo;
      } else {
        this.setDefaultData();
      }
      await this.fetchLocales();
      if (this.presetData.locale !== undefined) {
        this.selectedLocale = this.presetData.locale;
      }
    }
  }
}
</script>

<style src="./styles.scss" lang="scss"></style>
